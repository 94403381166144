import "animate.css/animate.compat.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "bulma/bulma.sass";
import "./main.scss";

window.onload = function () {
	AOS.init();

	const navbarBurger = document.querySelector(".navbar-burger");

	navbarBurger.addEventListener("click", () => {
		// select target menu using dataset-target attr.
		const target = navbarBurger.dataset.target;
		const targetMenu = document.getElementById(target);

		// toggle is-active class on button
		navbarBurger.classList.toggle("is-active");

		// toggle is-active class on menu
		targetMenu.classList.toggle("is-active");
	});
};
